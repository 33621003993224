import React, { useEffect } from 'react';
import { FiCheck, FiX } from 'react-icons/fi';

export default function PasswordStrength(props) {
  const { children, show, password, validPassword } = props;

  // Contains numbers
  const hasNumbers = /\d/.test(password);
  const numbersClass = hasNumbers ? 'password-strength__text--success' : 'password-strength__text--danger';

  // Contains uppercase
  const hasUppercase = /[A-Z]/.test(password);
  const uppercaseClass = hasUppercase ? 'password-strength__text--success' : 'password-strength__text--danger';

  // Contains lowercase
  const hasLowercase = /[a-z]/.test(password);
  const lowercaseClass = hasLowercase ? 'password-strength__text--success' : 'password-strength__text--danger';

  // Contains special characters
  const hasSpecialCharacters = /[^A-Za-z0-9]/.test(password);
  const specialCharactersClass = hasSpecialCharacters ? 'password-strength__text--success' : 'password-strength__text--danger';

  // Check password length
  const hasLength = password.length >= 8;
  const lengthClass = hasLength ? 'password-strength__text--success' : 'password-strength__text--danger';

  useEffect(() => {
    validPassword(hasNumbers && hasUppercase && hasLowercase && hasSpecialCharacters && hasLength);
  }, [password]);

  return (
    <div className="password-strength-wrapper">
      {show && (
        <div className="password-strength">
          <div className="password-strength__header">
            <h3 className="password-strength__title">Sua senha deve conter:</h3>
          </div>

          <div className="password-strength__body">
            <p className={`password-strength__text ${numbersClass}`}>
              {hasNumbers ? <FiCheck className="password-strength__icon" /> : <FiX className="password-strength__icon" />}
              pelo menos 1 número;
            </p>
            <p className={`password-strength__text ${uppercaseClass}`}>
              {hasUppercase ? <FiCheck className="password-strength__icon" /> : <FiX className="password-strength__icon" />}
              pelo menos 1 letra maiúscula;
            </p>
            <p className={`password-strength__text ${lowercaseClass}`}>
              {hasLowercase ? <FiCheck className="password-strength__icon" /> : <FiX className="password-strength__icon" />}
              pelo menos 1 letra minúscula;
            </p>
            <p className={`password-strength__text ${specialCharactersClass}`}>
              {hasSpecialCharacters ? <FiCheck className="password-strength__icon" /> : <FiX className="password-strength__icon" />}
              pelo menos 1 símbolo especial e;
            </p>
            <p className={`password-strength__text ${lengthClass}`}>
              {hasLength ? <FiCheck className="password-strength__icon" /> : <FiX className="password-strength__icon" />}
              Ter no mínimo 8 caracteres
            </p>
          </div>
        </div>
      )}

      {children}
    </div>
  );
}
